




<script>



/**
 * Agent Basic Info component
 */


//import DefaultAvatar from '@/assets/images/users/avatar-1.jpg'

export default {



    props: ['data','show_term_btn'],
    emit:['terminate'],
    components: {

    },


    data() {

        return {
            agent: {

            }
        }

    },
    watch: {
        data(n) {
            this.agent = n
        }
    },


    methods: {

    },

    created() {

    },

    mounted() {

    }

};
</script>


<template>
    <div class="row">
        <div class="card">
            <div class="card-body">

                <div class="row">
                    <div class="col-lg-2">
                        <img :src="agent.picture" alt="" class="img-thumbnail" />
                    </div>

                    <div class="col-lg-2">

                        <div class="mt-2">
                            <h5 class="font-size-16 mb-1">{{ agent.first_name + ' ' + agent.last_name }}</h5>
                            <p class="text-muted mb-0">VIN:{{ agent.code }}</p>
                        </div>
                        <div class="mt-2">
                            <p class="text-muted mb-1">Name :</p>
                            <h5 class="font-size-14 text-truncate">{{ agent.first_name + ' ' + agent.last_name }}</h5>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="mt-2">
                            <p class="text-muted mb-1">E-mail :</p>
                            <h5 class="font-size-14 text-truncate">{{ agent.email }}</h5>
                        </div>

                        <div class="mt-2">
                            <p class="text-muted mb-1">Phone :</p>
                            <h5 class="font-size-14 text-truncate">
                                {{ agent.phone }}
                            </h5>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="mt-4 d-flex align-items-right flex-wrap justify-content-end" v-if="show_term_btn">
                            <b-button variant="danger" @click="$emit('terminate')">Terminate</b-button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- end card -->
    </div>
</template>